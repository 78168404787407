<template>
  <div style="position: relative">
    <div id="map_container">
      <v-overlay opacity="0.5" z-index="1" absolute v-model="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="150"
          width="10"
        ></v-progress-circular>
      </v-overlay>
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import { init } from "@/utils/gmaps.js";

export default {
  props: {
    center: Object,
    zoom: Number,
  },
  data() {
    return {
      map: null,
      loading: true,
      marker: null,
      google: null,
    };
  },
  async mounted() {
    this.google = await init();

    this.map = new this.google.maps.Map(this.$el.querySelector("#map"), {
      center: this.center,
      zoom: this.zoom,
    });

    this.putMarker(this.center);

    this.google.maps.event.addListener(this.map, "click", (event) => {
      this.marker.setMap(null);
      let pos = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };

      this.putMarker(pos);
      this.$emit("changePos", pos);
    });

    this.loading = false;
  },
  methods: {
    putMarker(pos) {
      this.marker = new this.google.maps.Marker({
        position: {
          lat: Number(pos.lat),
          lng: Number(pos.lng),
        },
        map: this.map,
      });
    },
  },
  watch: {
    center: {
      handler(n) {
        try {
          this.marker.setMap(null);
          this.map.panTo({ lat: Number(n.lat), lng: Number(n.lng) });
          this.putMarker({ lat: Number(n.lat), lng: Number(n.lng) });
        } catch (error) {
          //pass
        }
      },
      deep: true,
      immediate: false,
    },
    zoom: {
      handler(n) {
        try {
          this.map.setZoom(n);
        } catch {
          /*pass*/
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#map_container {
  height: 100%;
  #map {
    height: 100%;
  }
}
</style>
