<template>
  <v-expansion-panel
    class="pa-0"
    link
    dense
    @click.stop="getMenu(Number(item.id_old))"
  >
    <v-expansion-panel-header :title="item.nombre"
      >{{ item.nombre }}<v-spacer></v-spacer>

      <v-checkbox
        v-if="!noCheckbox"
        dense
        :value="item.id_old"
        @click.stop
        @change="changeValue"
        class="flex-grow-0"
        color="primary"
        ref="checkbox"
      ></v-checkbox>

      <v-btn
        v-if="manageControls"
        @click.stop="manage(item.id_old)"
        icon
        small
        class="flex-grow-0"
      >
        <v-icon small>mdi-wrench</v-icon>
      </v-btn>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <PlaceMenu
        :manageControls="manageControls"
        :noCheckbox="noCheckbox"
        v-for="item in nodes.filter((i) => i.pertenea == item.id_old)"
        :key="item.id_old"
        :nodes="nodes"
        :item="item"
        :checkIfSelected="checkIfSelected"
        @get-menu="getMenu"
        @addcheckbox="addCheckbox"
        @removecheckbox="removeCheckbox"
      >
      </PlaceMenu>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
export default {
  name: "PlaceMenu",
  props: {
    item: { type: Object },
    nodes: { type: Array },
    checkIfSelected: { type: Array },
    noCheckbox: { type: Boolean, default: false },
    manageControls: { type: Boolean, default: false },
  },
  data() {
    return { isChecked: false };
  },
  methods: {
    getMenu(id_old) {
      this.$emit("get-menu", id_old);
    },
    changeValue(value) {
      if (value) this.$emit("addcheckbox", value, this.item.nombre);
      else this.$emit("removecheckbox", this.item.id_old, this.item.nombre);
    },
    addCheckbox(value, nombre) {
      this.$emit("addcheckbox", value, nombre);
    },
    removeCheckbox(value, nombre) {
      this.$emit("removecheckbox", value, nombre);
    },
    addMenuEntry(id_old) {
      this.$emit("addMenu", id_old);
    }
  },
  mounted() {
    if (
      this.checkIfSelected != undefined &&
      this.checkIfSelected.includes(this.item.id_old)
    ) {
      this.$refs.checkbox.$el.click();
      this.$refs.checkbox.$el.firstChild.firstChild.click();
    }
  },
};
</script>

<style>
</style>