<template>
  <v-form @submit.prevent="enviar">
    <div align="center" class="d-flex align-center" wrap="nowrap">
      <v-autocomplete
        v-model="model"
        :items="items"
        :loading="isLoading"
        :search-input.sync="search"
        color="primary"
        hide-no-data
        hide-selected
        item-text="nombre"
        item-value="id_old"
        label="Búsqueda de lugares"
        placeholder="Escribe para empezar a buscar"
        return-object
        :filter="filterObject"
        hide-details
      >
        <template v-slot:no-data>Busca alguna</template>
        <template v-slot:item="data">
          <v-list-item-icon v-if="!data.item.imagen_defecto">
            <v-icon>mdi-church</v-icon>
          </v-list-item-icon>
          <v-list-item-avatar v-else>
            <v-img
              :src="`/static/lugares/${data.item.imagen_defecto}`"
              alt="imagen_predefinida"
              @error="data.item.imagen_defecto = null"
            ></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.cp"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-btn
        :large="$vuetify.breakpoint.mobile ? false : true"
        color="primary"
        type="submit"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import axios from "redaxios";
import _ from "lodash";

export default {
  data() {
    return {
      count: null,
      isLoading: false,
      entries: [],
      model: null,
      search: this.busqueda,
    };
  },
  computed: {
    items() {
      return this.entries;
    },
  },
  watch: {
    model(val) {
      this.$router.push({ name: "Lugar", params: { id: Number(val.id_old) } });
    },
    search(val) {
      if (val === "" || val === null || val.length <= 2) {
        this.entries = [];
        return;
      }

      this.busca(val);

      if (this.isLoading == true) return;

      this.isLoading = true;
    },
  },
  methods: {
    enviar() {
      if (this.search != "" && this.search != null) {
        this.$router.push({
          name: "Busqueda",
          query: { busqueda: this.search },
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    filterObject(item, queryText, itemText) {
      return this.entries;
    },
    busca: _.debounce(function (val) {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/lugares.php`,
        params: {
          busqueda: val,
          pagina: 1,
        },
      })
        .then((res) => {
          this.count = res.data.resultados.length;
          this.entries = res.data.resultados.slice(0, 15);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => (this.isLoading = false));
    }, 500),
  },
};
</script>

<style>
</style>