<template>
  <v-card
    class="pa-3"
    elevation="0"
    width="100%"
    max-width="250px"
    max-height="100%"
  >
    <v-card-title>{{ tipo }}</v-card-title>
    <v-row align="center" no-gutters>
      <v-col cols="12" class="col-md-4">
        <v-img
          :src="require('@/assets/no_disponible.png')"
          alt="imagen_recomendado"
        ></v-img>
      </v-col>
      <v-col cols="12" class="col-md-8">
        <v-card-subtitle>{{ nombre }}</v-card-subtitle>
        <v-btn @click="detalles" text color="primary">Ver detalles</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="shown" width="unset" max-width="900px">
      <v-card class="pa-4">
        <v-row no-gutters>
          <v-card-title>{{ nombre }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="shown = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" class="col-md-6">
            <v-img
              contain
              :src="require('@/assets/no_disponible.png')"
              alt="imagen_recomendado"
            ></v-img>
          </v-col>
          <v-col cols="12" class="col-md-6">
            <v-card-text class="text-justify"
              >Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias
              corrupti ullam repellat quia dicta iste temporibus, accusamus
              provident sunt molestias cumque tenetur ratione excepturi fugit,
              hic quo, rem ipsum eveniet ex in! Optio debitis ut deserunt, enim
              corporis saepe neque deleniti fugit, voluptates unde repellat
              atque. At porro velit modi in dolorem eius vitae, laborum
              accusantium expedita aut neque ipsum eaque facere repellendus
              numquam natus. Illum, saepe. Consectetur deserunt fuga magnam
              dolore enim expedita, reiciendis quisquam illum beatae nobis
              dignissimos dicta asperiores nisi sit nulla rem est consequuntur
              ex distinctio veritatis deleniti vitae nam? Voluptates reiciendis
              dignissimos sint laborum id.</v-card-text
            >
            <v-divider></v-divider>
            <v-row justify="space-around" class="py-2">
              <div>
                <v-icon color="primary">mdi-heart</v-icon>
                <br />
                <span>200</span>
              </div>
              <div>
                <v-icon color="primary">mdi-bookmark</v-icon>
                <br />
                <span>200</span>
              </div>
              <div>
                <v-icon color="primary">mdi-comment</v-icon>
                <br />
                <span>200</span>
              </div>
            </v-row>
            <v-divider></v-divider>
            <v-row no-gutters justify="center" align="center">
              <Anuncio />
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn :to="`/lugar/${id}`" text color="primary">Ver el lugar</v-btn>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
const Anuncio = () => import("@/components/Anuncio.vue");

export default {
  components: { Anuncio },
  props: {
    nombre: String,
    tipo: String,
    img_src: String,
    id: Number,
  },
  data() {
    return {
      shown: false,
    };
  },
  computed: {
    img() {
      let r;
      try {
        r = `/static/lugares/${this.id}`;
      } catch {
        r = require("@/assets/no_disponible.png");
      }
      return r;
    },
  },
  methods: {
    detalles() {
      this.shown = !this.shown;
    },
  },
};
</script>

<style>
</style>