<template>
  <v-container>
    <v-row class="ma-auto">
      <v-col cols="12" class="ma-0 pa-2 col-12 col-sm-12 col-lg-9">
        <v-card class="busqueda-mapa">
          <v-container fluid>
            <Busqueda />
          </v-container>
          <Map
            :center="$store.getters.getClickedMarker || position"
            :zoom="
              $store.getters.getZoomLevel
                ? $store.getters.getZoomLevel
                : $store.getters.hasPositionChanged
                ? 12
                : 7
            "
          />
        </v-card>
      </v-col>
      <v-col cols="12" class="ma-0 pa-2 col-12 col-sm-12 col-lg-3">
        <v-card>
          <v-card-title>Lugares recomendados</v-card-title>
          <v-divider></v-divider>
          <v-row
            no-gutters
            style="overflow-y: auto; max-height: 550px; height: 100%"
          >
            <Recomendado
              nombre="Catedral de Santiago"
              tipo="Lugar más visitado"
              :id="1"
            />
            <Recomendado
              nombre="Catedral de Burgos"
              tipo="Lugar más visitado"
              :id="2"
            />
            <Recomendado
              nombre="Catedral de Lugo"
              tipo="Lugar más visitado"
              :id="3"
            />
            <Recomendado
              nombre="Sagrada Familia"
              tipo="Lugar más visitado"
              :id="4"
            />
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" class="ma-0 pa-2">
        <v-row>
          <v-col cols="12" class="col-sm-6 col-md-4">
            <Anuncio :src="require('@/assets/booking2.png')" />
          </v-col>
          <v-col cols="12" class="col-sm-6 col-md-4">
            <Anuncio :src="require('@/assets/camino_de_santiago.jpg')" />
          </v-col>
          <v-col cols="12" class="col-sm-6 col-md-4">
            <Anuncio :src="require('@/assets/ibercaja.jpg')" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="col-md-6">
        <v-card height="600px">
          <v-btn
            rel="noopener"
            href="http://evangeli.net"
            target="_blank"
            title="Abrir evangeli.net"
            small
            fab
            absolute
            top
            right
            ><v-icon color="primary">mdi-arrow-top-right-thick</v-icon></v-btn
          >
          <iframe
            frameborder="0"
            width="100%"
            height="100%"
            src="https://evangeli.net/evangelio/widget/web"
          ></iframe>
        </v-card>
      </v-col>
      <v-col cols="12" class="col-md-6">
        <v-card height="600px" style="overflow-y: auto">
          <v-row align="center" no-gutters>
            <v-card-title>Santoral de hoy</v-card-title>
            <v-btn
            v-if="mes != 0 || dia != 0 || santo != null"
              icon
              @click.stop="
                mes = 0;
                (dia = 0), (santo = null);
              "
              ><v-icon color="primary">mdi-restart</v-icon></v-btn
            >
          </v-row>
          <v-row no-gutters align="center" class="flex-nowrap">
            <small class="ma-2">Busca por fecha...</small>
            <v-select
              v-model="dia"
              dense
              hide-details
              filled
              class="mx-2"
              placeholder="Día"
              :items="dias"
            ></v-select>
            <v-select
              v-model="mes"
              dense
              hide-details
              filled
              class="mx-2"
              placeholder="Mes"
              :items="meses"
            ></v-select>
          </v-row>
          <v-row no-gutters class="ma-2" align="center">
            <small>O por nombre...</small>
            <v-text-field
              filled
              color="primary"
              dense
              hide-details
              append-icon="mdi-magnify"
              class="ml-2"
              placeholder="Escribe el nombre que quieras buscar"
              v-model="santo"
            />
          </v-row>
          <v-divider></v-divider>
          <small class="ma-4">Busqueda por... {{ busquedaSanto }}</small>
          <v-list>
            <v-list-item v-for="(santo, index) in santoral" :key="index">
              <v-chip label class="mr-4" v-if="!!santo.dia">
                {{ santo.dia }}/{{ santo.mes }}
              </v-chip>
              <template> {{ santo.nombre }} - {{ santo.santo }} </template>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-container class="mt-4" fluid style="text-align: center">
      <p class="text-h4 d-block">¿Conoces algún sitio que no tenemos?</p>
      <v-btn large color="primary" @click.prevent="abrirSolicitud"
        >Cuéntanoslo</v-btn
      >
      <v-dialog persistent max-width="1400" v-model="solicitud.show">
        <EditForm
          @addPlace="enviarSolicitud"
          @closeModal="solicitud.show = false"
        />
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import Recomendado from "@/components/Recomendado.vue";
import Map from "@/components/Map.vue";
import Busqueda from "@/components/Busqueda.vue";
import Anuncio from "@/components/Anuncio.vue";
import EditForm from "@/components/LugaresControl/EditForm.vue";

import axios from "redaxios";
import _ from "lodash";
import { req, min, email } from "@/utils/validationRules.js";

export default {
  name: "Home",
  components: {
    Map,
    Busqueda,
    Recomendado,
    Anuncio,
    EditForm,
  },
  data() {
    return {
      santoral: [],

      dias: Array.from({ length: 31 }, (x, i) => i + 1),
      meses: Array.from({ length: 12 }, (x, i) => i + 1),

      dia: 0,
      mes: 0,
      santo: null,

      busquedaSanto: `${new Date().getDate()}-${new Date().getMonth() + 1}`,

      solicitud: {
        show: false,
        rules: {
          req: req,
          min: (v) => min(v, 200),
          email: email,
        },
      },
    };
  },
  computed: {
    position: function () {
      return this.$store.getters.getPosition;
    },
  },
  mounted() {
    this.santoHoy();
  },
  methods: {
    santoHoy() {
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/santoral.php`,
        params: {
          dia: new Date().getDate(),
          mes: new Date().getMonth() + 1,
        },
      }).then((res) => {
        this.santoral = res.data;
        this.busquedaSanto = `${new Date().getDate()}-${
          new Date().getMonth() + 1
        }`;
      });
    },

    santoFecha() {
      if (!this.dia || !this.mes) {
        this.santoHoy();
        return;
      }
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/santoral.php`,
        params: {
          dia: this.dia > 0 ? this.dia : new Date().getDate(),
          mes: this.mes > 0 ? this.mes : new Date().getMonth() + 1,
        },
      }).then((res) => {
        this.santoral = res.data;
        this.busquedaSanto = `${
          this.dia > 0 ? this.dia : new Date().getDate()
        }-${this.mes > 0 ? this.mes : new Date().getMonth() + 1}`;
      });
    },

    buscarSanto: _.debounce(function (val) {
      if (val == null || val == "" || val.length <= 2) {
        this.santoHoy();
        return;
      }
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/santoral.php`,
        params: { nombre: val },
      }).then((res) => {
        this.santoral = res.data;
        this.busquedaSanto = val;
      });
    }, 500),

    abrirSolicitud() {
      if (!this.$store.getters.isLoggedIn) {
        this.$root.$emit("snack", "Tienes que iniciar sesión para hacer esto");
        this.$router.push({ name: "Login" });
        return;
      }
      this.solicitud.show = true;
    },
    enviarSolicitud(lugar) {
      console.log(lugar);
    },
  },
  watch: {
    santo(val) {
      this.buscarSanto(val);
    },
    dia() {
      this.santoFecha();
    },
    mes() {
      this.santoFecha();
    },
  },
};
</script>

<style lang="scss" scoped>
.busqueda-mapa {
  height: 100%;
  display: grid;
  grid-template-rows: 0fr 1fr;
  min-height: 600px;
}
</style>
