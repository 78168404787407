<template>
  <v-sheet height="100%">
    <v-img v-if="src" :src="src" contain alt="anuncio" eager></v-img>
    <span v-else v-text="text"></span>
  </v-sheet>
</template>

<script>
export default {
  props: {
		src : {},
		text : {default: 'Anuncio'}
	},
};
</script>