<template>
  <v-form @submit.prevent="addPlace" ref="form" class="pa-0">
    <v-stepper non-linear v-model="step">
      <v-stepper-header>
        <v-stepper-step color="primary" editable step="1"
          >MENU<small
            >Selecciona a que entradas del menú pertenece</small
          ></v-stepper-step
        >
        <v-stepper-step color="primary" editable step="2"
          >DATOS<small>Rellena los datos del lugar</small></v-stepper-step
        >
        <v-stepper-step color="primary" editable step="3"
          >POSICIÓN<small
            >La localización en el mapa del lugar</small
          ></v-stepper-step
        >
      </v-stepper-header>
      <v-stepper-content step="1">
        <v-row>
          <v-col cols="12" class="col-md-4">
            <v-card>
              <v-expansion-panels
                v-model="menu.openedPanels"
                dense
                accordion
                multiple
              >
                <PlaceMenu
                  v-for="item in menu.data.filter((item) => item.pertenea == 1)"
                  :nodes="menu.data"
                  :key="item.id_old"
                  :item="item"
                  :checkIfSelected="place.perteneceAOld"
                  @get-menu="getMenu"
                  @addcheckbox="addCheckbox"
                  @removecheckbox="removeCheckbox"
                  class="pa-0"
                >
                </PlaceMenu>
              </v-expansion-panels>
            </v-card>
          </v-col>
          <v-col>
            <v-card-title>Seleccionadas</v-card-title>
            <v-divider></v-divider>
            <v-list dense>
              <v-list-item
                dense
                :key="index"
                v-for="(s, index) in seleccionados"
              >
                {{ s }}
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-row no-gutters>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Nombre"
            filled
            v-model="place.nombre"
            :rules="[rules.req]"
          ></v-text-field>
        </v-row>
        <v-row no-gutters>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Código Postal"
            v-model="place.cp"
            filled
          ></v-text-field>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Provincia"
            v-model="place.provincia"
            filled
          ></v-text-field>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Dirección"
            v-model="place.direccion"
            filled
          ></v-text-field>
        </v-row>
        <v-row no-gutters>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Diócesis"
            v-model="place.diocesis"
            filled
          ></v-text-field>
          <v-text-field
            color="primary"
            class="mx-1"
            label="País"
            v-model="place.pais"
            :rules="[rules.req]"
            filled
          ></v-text-field>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Población"
            v-model="place.poblacion"
            filled
          ></v-text-field>
        </v-row>

        <v-card-subtitle>Descripción</v-card-subtitle>

        <quill-editor
          class="my-4"
          ref="myQuillEditor"
          v-model="place.descripcion"
          :options="{
            placeholder: 'Escribe aquí la descripción...',
            modules: {
              toolbar: toolbarOptions,
            },
          }"
        ></quill-editor>

        <v-row class="mt-4" no-gutters>
          <v-file-input
            prepend-icon=""
            prepend-inner-icon="$file"
            filled
            chips
            label="Imágenes"
            multiple
            accept="image/*"
            color="primary"
            v-model="place.imagenes"
            @change="nuevasFotos"
          >
            <template v-slot:selection="{ index, text }">
              <v-chip close @click:close="deleteChip(index, text)">
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-row>

        <v-row
          no-gutters
          v-if="[...nuevasImagenes, ...imagenesActuales].length > 0"
          class="d-flex flex-nowrap"
          style="overflow-x: auto"
        >
          <v-img
            v-for="(imagen, i) in [...nuevasImagenes, ...imagenesActuales]"
            :key="i"
            :src="imagen"
            contain
            max-width="300px"
            max-height="300px"
            class="ma-1"
          ></v-img>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <v-row no-gutters>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Latitud"
            v-model="place.coordenadas.lat"
            filled
          >
          </v-text-field>
          <v-text-field
            color="primary"
            class="mx-1"
            label="Longitud"
            v-model="place.coordenadas.lng"
            filled
          ></v-text-field>
          <v-btn
            class="ml-2"
            fab
            @click="place.coordenadas = $store.getters.getPosition"
            ><v-icon color="primary">mdi-restart</v-icon></v-btn
          >
        </v-row>
        <v-row no-gutters>
          <Map
            style="height: 500px; width: 100%"
            @changePos="changePos"
            :zoom="6"
            :center="place.coordenadas"
          />
        </v-row>
      </v-stepper-content>
    </v-stepper>
    <v-card
      class="d-flex flex-wrap pa-4"
      style="position: sticky; bottom: 0; z-index: 10"
    >
      <v-spacer></v-spacer>
      <v-btn class="mr-2" @click.stop="closeEdit" color="error">Cancelar</v-btn>
      <v-btn
        class="mr-2"
        :disabled="step >= 3"
        @click.stop="siguiente"
        color="primary"
        >Siguiente <v-icon>mdi-arrow-back-right</v-icon></v-btn
      >
      <v-btn class="mr-2" :disabled="step != 3" type="submit" color="primary"
        >Enviar</v-btn
      >
    </v-card>
  </v-form>
</template>

<script>
import axios from "redaxios";
import PlaceMenu from "@/components/LugaresControl/PlaceMenu.vue";
import Map from "@/components/LugaresControl/Map.vue";

import { req } from "@/utils/validationRules.js";

export default {
  components: { PlaceMenu, Map },
  data() {
    return {
      toolbarOptions: [
        ["bold", "italic", "underline", "strike"], // toggled buttons
        ["blockquote", "code-block"],

        [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: "ordered" }, { list: "bullet" }],
        [{ script: "sub" }, { script: "super" }], // superscript/subscript
        [{ indent: "-1" }, { indent: "+1" }], // outdent/indent

        [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        [{ header: [1, 2, 3, 4, 5, 6, false] }],

        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ font: [] }],
        [{ align: [] }],

        ["clean"], // remove formatting button
      ],
      step: 1,
      menu: {
        data: [],
        historial: [],
        historialIndex: -1,
        openedPanels: [],
        addMenuForm: {
          show: false,
          value: "",
          id_old: null,
        },
      },
      nuevasImagenes: [],
      imagenesActuales: [],
      seleccionados: [],

      place: {
        imagenes: [],
        nimagenes: 0,
        perteneceAOld: [],
        descripcion: "",
        nombre: "",
        cp: "",
        provincia: "",
        direccion: "",
        poblacion: "",
        diocesis: "",
        pais: "",
        coordenadas: {},
        perteneceA: [],
      },

      rules: {
        req: req,
      },
    };
  },
  methods: {
    getMenu(parent_id, back = false) {
      if (this.menu.historial.includes(parent_id)) return;
      if (!back) {
        this.menu.historial.push(parent_id);
        this.menu.historialIndex = this.menu.historial.length - 1;
      } else {
        this.menu.historialIndex > 0 ? this.menu.historialIndex-- : "";
        parent_id = this.menu.historial[this.menu.historialIndex];
      }

      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/menu.php`,
        headers: { "Content-Type": "application/json", charset: "utf-8" },
        params: {
          parent_id: parent_id,
        },
      })
        .then((response) => {
          this.menu.data = [
            ...this.menu.data,
            ...response.data.filter((item) => item.tipo != "LUGAR"),
          ];
        })
        .catch((error) => console.error(error));
    },
    deleteChip(index) {
      this.place.imagenes.splice(index, 1);
      this.nuevasFotos();
    },
    nuevasFotos() {
      this.nuevasImagenes = [
        ...this.place.imagenes.map((img) => URL.createObjectURL(img)),
      ];
    },
    addCheckbox(value, nombre) {
      this.place.perteneceA.push(value);
      this.place.perteneceA = [...new Set(this.place.perteneceA)];
      this.seleccionados.push(nombre);
    },
    removeCheckbox(value, nombre) {
      this.place.perteneceA.splice(this.place.perteneceA.indexOf(value), 1);
      this.seleccionados.splice(this.seleccionados.indexOf(nombre), 1);
    },
    closeEdit() {
      this.$emit("closeModal");
    },
    addPlace() {
      if (!this.$refs.form.validate()) return;
      this.$emit("addPlace", this.place);
      this.$emit("closeModal");
    },
    changePos(pos) {
      this.place.coordenadas = pos;
    },
    siguiente() {
      if (this.$refs.form.validate() || this.step != 2) this.step++;
    },
  },
  mounted() {
    this.place.coordenadas = this.$store.getters.hasPositionChanged
      ? this.$store.getters.getPosition
      : { lat: 0, lng: 0 };

    this.getMenu(1);

    this.$on("getmenu", (id_old) => {
      this.getMenu(id_old);
    });

    this.$on("addcheckbox", (value) => {
      this.addCheckbox(value);
    });
  },
};
</script>

<style>
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  min-height: 100px;
}
</style>