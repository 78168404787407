<template>
  <div style="position: relative">
    <v-chip
      class="distancia"
      pill
      :style="`${hover ? 'opacity: 0; pointer-events: none' : ''}`"
      color="primary"
      v-if="distancia"
      >a {{ distancia }}</v-chip
    >

    <v-fab-transition>
      <v-btn
        v-show="!passedMarker && $store.getters.getClickedMarker"
        fab
        absolute
        bottom
        color="white"
        class="mb-16 ml-2"
        @click="
          $store.dispatch('saveClickedMarker', null);
          $store.dispatch('saveZoomLevel', { zoom: null });
        "
      >
        <v-icon color="primary">mdi-restart</v-icon>
      </v-btn>
    </v-fab-transition>

    <div id="map_container">
      <v-overlay opacity="0.5" z-index="1" absolute v-model="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="150"
          width="10"
        ></v-progress-circular>
      </v-overlay>
      <div id="map" @mouseover="hover = true" @mouseout="hover = false"></div>
    </div>
    <v-btn
      v-if="
        passedMarker && $store.getters.hasPositionChanged && isRoute === true
      "
      :href="`https://www.google.com/maps/dir/?api=1&origin=${
        route.origin.lat
      },${route.origin.lng}&destination=${route.destination.lat},${
        route.destination.lng
      }&travelmode=${route.travelMode.toLowerCase()}`"
      target="_blank"
      color="primary"
      small
      class="verRuta"
    >
      Ver en google maps
    </v-btn>
    <template v-if="passedMarker && $store.getters.hasPositionChanged">
      <v-row no-gutters align="start" class="flex-nowrap" width="100%">
        <v-btn
          @click="calcRoute"
          color="primary"
          tile
          :elevation="0"
          class="flex-grow-1 pa-0 ma-0"
          style="height: 38px; width: 80%"
          :loading="route.loading"
        >
          Calcular ruta
        </v-btn>
        <v-select
          class="pa-0 ma-0 rounded-0 flex-grow-0"
          color="white"
          solo
          flat
          v-model="travelMode"
          :items="travelModes"
          item-value="value"
          item-text="icon"
          hide-details
          dense
          background-color="primary"
        >
          <v-icon slot="append" color="white">$dropdown</v-icon>
          <template v-slot:selection="{ item }">
            <v-icon color="white">{{ item.icon }}</v-icon>
          </template>

          <template v-slot:item="{ item }">
            <v-icon>{{ item.icon }}</v-icon>
            <span class="ml-2">{{ item.desc }}</span>
          </template>
        </v-select>
      </v-row>
    </template>
  </div>
</template>

<script>
import { init } from "@/utils/gmaps.js";
import MarkerClusterer from "@google/markerclusterer";

export default {
  props: {
    center: Object,
    zoom: Number,
    passedMarker: Object,
  },
  data() {
    return {
      map: null,
      isRoute: null,
      travelModes: [
        { value: "DRIVING", desc: "En coche", icon: "mdi-car" },
        { value: "WALKING", desc: "Caminando", icon: "mdi-walk" },
        { value: "BICYCLING", desc: "En bicicleta", icon: "mdi-bike" },
        { value: "TRANSIT", desc: "Transporte público", icon: "mdi-bus" },
      ],
      travelMode: "DRIVING",
      usedTravelMode: "",
      dr: null,
      ds: null,
      distancia: null,
      loading: true,
      hover: false,
    };
  },
  computed: {
    route() {
      if (this.passedMarker)
        return {
          origin: this.$store.getters.getPosition,
          destination: {
            lat: Number(this.passedMarker.latitude),
            lng: Number(this.passedMarker.longitude),
          },
          travelMode: this.travelMode,
          loading: false,
        };
      else return {};
    },
  },
  async mounted() {
    const google = await init();
    this.$nextTick(async () => {
      const map = new google.maps.Map(this.$el.querySelector("#map"), {
        center: this.center,
        zoom: this.zoom,
      });
      this.map = map;

      if (this.passedMarker == null) {
        if (this.$store.getters.getMarkers.length == 0)
          await this.$store.dispatch("getMarkers");
        const icon = require("@/assets/marker.svg");
        const locations = this.$store.getters.getMarkers.map(
          ({ latitude, longitude, nombre, id, cp }) => {
            const marker = new google.maps.Marker({
              position: {
                lat: Number(latitude) + (Math.random() - 0.1) / 1500,
                lng: Number(longitude) + (Math.random() - 0.1) / 1500,
              },
              map: map,
              title: nombre,
              icon: icon,
            });
            marker.addListener("click", () => {
              this.$store.dispatch("saveClickedMarker", {
                latitude,
                longitude,
              });
              this.$store.dispatch("saveZoomLevel", {
                zoom: map.getZoom(),
              });
              this.$router.push({
                name: "Lugar",
                params: { id: id },
              });
            });
            let infowindow = new google.maps.InfoWindow();
            if (cp != "undefined" && cp != null)
              infowindow.setContent(`<h4>${nombre}</h4><h5>${cp}</h5>`);
            else infowindow.setContent(`<h4>${nombre}</h4>`);

            google.maps.event.addListener(marker, "mouseover", () =>
              infowindow.open(map, marker)
            );
            google.maps.event.addListener(marker, "mouseout", () =>
              infowindow.close(map, marker)
            );

            return marker;
          }
        );
        new MarkerClusterer(map, locations, {
          imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        });
      } else {
        new google.maps.Marker({
          position: {
            lat: Number(this.passedMarker.latitude),
            lng: Number(this.passedMarker.longitude),
          },
          map: map,
          title: this.passedMarker.nombre,
          animation: google.maps.Animation.DROP,
          icon: require("@/assets/marker.svg"),
        });
        this.calcDistance();
      }
      this.ds = new google.maps.DirectionsService();
      this.dr = new google.maps.DirectionsRenderer();

      this.loading = false;
    });
  },
  methods: {
    calcRoute() {
      if (this.usedTravelMode == this.travelMode) return;
      this.route.loading = true;
      this.dr.setMap(this.map);
      this.ds.route(
        {
          origin: this.$store.getters.getPosition,
          destination: {
            lat: Number(this.passedMarker.latitude),
            lng: Number(this.passedMarker.longitude),
          },
          travelMode: this.travelMode,
        },
        (result, status) => {
          if (status == "OK") {
            this.dr.setDirections(result);
            this.$root.$emit("snack", "Se ha calculado la mejor ruta");
            this.isRoute = true;
            this.usedTravelMode = this.travelMode;
          } else {
            this.$root.$emit("snack", "No se he encontrado ninguna ruta");
            this.isRoute = false;
            this.dr.setMap(null);
          }
        }
      );
      this.route.loading = false;
    },
    calcDistance() {
      let degreesToRadians = (deg) => (deg * Math.PI) / 180;
      const earthRadiusKm = 6371;

      let { lat, lng } = this.$store.getters.getPosition;
      let { latitude, longitude } = this.passedMarker;

      let dLat = degreesToRadians(latitude - lat);
      let dLon = degreesToRadians(longitude - lng);

      lat = degreesToRadians(lat);
      latitude = degreesToRadians(latitude);

      let a =
        Math.pow(Math.sin(dLat / 2), 2) +
        Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat) * Math.cos(latitude);
      let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      let km = parseFloat(earthRadiusKm * c).toFixed(2);
      this.distancia = km >= 1 ? `${km} km` : `${km * 1000} m`;
    }
  },
  watch: {
    center: {
      handler(n) {
        try {
          this.map.panTo(n);
          // eslint-disable-next-line no-empty
        } catch {}
      },
      deep: true,
    },
    zoom: {
      handler(n) {
        try {
          this.map.setZoom(n);
          // eslint-disable-next-line no-empty
        } catch {}
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
#map_container {
  height: 100%;
  #map {
    height: 100%;
  }
}

.distancia {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.verRuta {
  position: absolute;
  bottom: 10px;
}

.v-select--append .v-icon {
  color: white;
}
</style>
